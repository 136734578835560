import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';
import moment from 'moment-timezone';

import { request } from '../utils/LodgebookAPIClient';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from './TaskStore';

export const ROOMS_URL = '/rooms';

export const VACANCY_STATUS = {
  VACANT: 'vacant',
  CHECKOUT: 'checkout',
  STAYOVER: 'stayover',
  BLOCKED: 'blocked',
};

export const CLEAN_STATUS = {
  CLEAN: 'clean',
  DIRTY: 'dirty',
  SHOULD_INSPECT: 'should_inspect',
};

export const SYNXIS_VACANCY_STATUS_MAP = {
  'C/O': 'checkout',
  Stay: 'stayover',
  New: 'stayover',
  OOO: 'blocked',
  DIRTY: 'dirty',
  Block: 'stayover',
  CLEAN: 'clean',
};

export const DO_NOT_DISTURB_STATUS = 'do not disturb';

export const COLORS = {
  CLEAN: '#20B2AA',
  DIRTY: '#F9A148',
  BLOCKED: '#EB4D5F',
  DO_NOT_DISTURB: '#F6A4B2',
  SHOULD_INSPECT: '#95E6E4',
};

export const Room = types
  .model('Room', {
    id: types.identifierNumber,
    number: types.string,
    vacancyStatus: types.enumeration(Object.values(VACANCY_STATUS)),
    cleanStatus: types.enumeration(Object.values(CLEAN_STATUS)),
    doNotDisturb: types.boolean,
    isGeneralArea: types.maybeNull(types.boolean),
    iconName: types.maybeNull(types.string),
    name: types.frozen({
      english: types.string,
      spanish: types.string,
      chinese: types.string,
    }),
  })
  .views((self) => ({
    get tasks() {
      const root = getRoot(self);
      return root.taskStore.tasksAsArray.filter(
        (task) => task.roomId === self.id
      );
    },
    get incompleteTasks() {
      const filteredTasks = self.tasks.filter(
        (task) => task.status === INCOMPLETE_STATUS && task.roomId === self.id
      );
      return filteredTasks.sort((a, b) => b.createdAt - a.createdAt);
    },
    get completedTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      const filteredTasks = self.tasks.filter((task) => {
        return (
          task.status === COMPLETE_STATUS &&
          (!task.completedAt ||
            moment(task.completedAt)
              .tz(timeZone)
              .isSame(moment().tz(timeZone), 'day'))
        );
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
    get completedBeforeTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      const filteredTasks = self.tasks.filter((task) => {
        return (
          task.status === COMPLETE_STATUS &&
          !moment(task.completedAt)
            .tz(timeZone)
            .isSame(moment().tz(timeZone), 'day')
        );
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
    get tasksCompletedLastMonth() {
      const filteredTasks = self.tasks.filter((task) => {
        const completedAtMoment = moment(task.completedAt);
        const taskCompletedInLastMonth = completedAtMoment.isSameOrAfter(
          moment().subtract(30, 'days'),
          'day'
        );
        return task.status === COMPLETE_STATUS && taskCompletedInLastMonth;
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
    get translatedName() {
      const root = getRoot(self);
      const { userStore } = root;
      const { authenticatedUser } = userStore;
      if (self.isGeneralArea) {
        return (
          self?.name[authenticatedUser?.languagePreference] ??
          self?.name['english'] ??
          self.number
        );
      }

      return self.number;
    },
  }));

export const sortRoomsByType = (rooms) => {
  const regularRooms = [];
  const generalAreas = [];

  rooms.forEach((room) => {
    if (room.isGeneralArea) {
      generalAreas.push(room);
    } else {
      regularRooms.push(room);
    }
  });

  return {
    rooms: regularRooms,
    generalAreas,
  };
};

const RoomStore = types
  .model('RoomStore', {
    rooms: types.optional(types.map(Room), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingOne: types.optional(types.boolean, false),
    isUpdatingFirstMenuItem: types.optional(types.boolean, false),
    isUpdatingSecondMenuItem: types.optional(types.boolean, false),
    isUpdatingThirdMenuItem: types.optional(types.boolean, false),
    isUpdatingFourthMenuItem: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get roomsAsArray() {
      return values(self.rooms);
    },
    get roomsInNumericalOrder() {
      return self.roomsAsArray.sort((a, b) => {
        // Check if both strings are numeric
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        if (!isNaN(numA) && !isNaN(numB)) {
          // If both are numeric, sort by their numeric values
          return numA - numB;
        } else if (isNaN(numA) && isNaN(numB)) {
          // If neither is numeric, sort as strings
          return a.number.localeCompare(b.number);
        } else {
          // If one is numeric and the other is not, place numeric values first
          return isNaN(numA) ? 1 : -1;
        }
      });
    },
    get roomsInNumericalOrderByType() {
      return sortRoomsByType(self.roomsInNumericalOrder);
    },
    get firstRoom() {
      return self.roomsInNumericalOrder[0];
    },
    get roomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.length;
    },
    get stayoverRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.vacancyStatus === VACANCY_STATUS.STAYOVER
      ).length;
    },
    get vacantRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.vacancyStatus === VACANCY_STATUS.VACANT
      ).length;
    },
    get checkoutRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.vacancyStatus === VACANCY_STATUS.CHECKOUT
      ).length;
    },
    get blockedRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.vacancyStatus === VACANCY_STATUS.BLOCKED
      ).length;
    },
    get cleanRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.cleanStatus === CLEAN_STATUS.CLEAN
      ).length;
    },
    get dirtyRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.cleanStatus === CLEAN_STATUS.DIRTY
      ).length;
    },
    get doNotDisturbRoomCount() {
      return sortRoomsByType(values(self.rooms)).rooms.filter(
        (room) => room.doNotDisturb === true
      ).length;
    },
  }))
  .actions((self) => ({
    fetchAllRooms: flow(function*(hotelId) {
      self.networkError = undefined;
      self.isFetchingAll = true;
      try {
        const roomsResponse = yield request(
          `${ROOMS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.rooms = {};
        roomsResponse.rooms.forEach((room) => {
          self.rooms.set(room.id, room);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch rooms', error);
      }
      self.isFetchingAll = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
    fetchRoom: flow(function*(roomId) {
      try {
        const { room } = yield request(`${ROOMS_URL}/${roomId}`, 'GET');
        self.rooms.set(room.id, room);
      } catch (error) {
        console.log(error);
      }
    }),
    updateRoom: flow(function*({
      roomId,
      options,
      errorNotificationText,
      shouldThrowError = false,
    }) {
      /**
       * There are cases where we want to catch the thrown error outside of this method,
       * such as if we want to display a spinner while this method is running and manually handle an error instead of displaying it in the notification overlay
       */
      let errorToBeThrown;

      self.isFetchingOne = true;
      self.isUpdatingFirstMenuItem = 'isUpdatingFirstMenuItem' in options;
      self.isUpdatingSecondMenuItem = 'isUpdatingSecondMenuItem' in options;
      self.isUpdatingThirdMenuItem = 'isUpdatingThirdMenuItem' in options;
      self.isUpdatingFourthMenuItem = 'isUpdatingFourthMenuItem' in options;

      try {
        const updatedRoom = yield request(`${ROOMS_URL}/${roomId}`, 'PATCH', {
          body: {
            room: options.body,
          },
        });
        self.rooms.set(roomId, updatedRoom.room);
      } catch (error) {
        errorToBeThrown = error;
        self.networkError = JSON.stringify(error);
        console.warn('Failed to update room', error);

        if (!shouldThrowError && errorNotificationText) {
          getRoot(self).notificationStore.createNotification({
            additionalId: roomId,
            additionalType: Room.name,
            text: errorNotificationText,
            callback: () =>
              self.updateRoom({
                roomId,
                options,
                errorNotificationText,
              }),
          });
        }
      }

      self.isFetchingOne = false;
      self.isUpdatingFirstMenuItem = false;
      self.isUpdatingSecondMenuItem = false;
      self.isUpdatingThirdMenuItem = false;
      self.isUpdatingFourthMenuItem = false;

      if (shouldThrowError && errorToBeThrown) {
        throw errorToBeThrown;
      }

      return true;
    }),

    updateRooms: flow(function*(body, hotelId) {
      self.isFetchingAll = true;
      try {
        yield request(`${ROOMS_URL}?hotel_id=${hotelId}`, 'PUT', {
          body: {
            rooms: body,
          },
          parseResponse: false,
        });

        self.fetchAllRooms(hotelId);
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.warn('Failed to update rooms', error);
      }
      self.isFetchingAll = false;
    }),
  }));

export default RoomStore;
